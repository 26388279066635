import React, { useState } from "react"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import { chunk } from "./../../src/util/helper"
import BlogCard from "./../../src/components/core/BlogCard"
import H2 from "../components/core/H2"
import Layout from "../Layout"
import CategoryFilters from "../components/core/CategoryFilters"

const CategoryTemplate = ({ data ,location}) => {
  const { allWpPost, category ,blogData } = data
  //  eslint-disable-next-line no-unused-vars
  const [totalCount, setTotalCount] = useState(allWpPost.nodes.length)
  const [postsToShow, setPostsToShow] = useState(12)
  const handleLoadMore = e => {
    setPostsToShow(postsToShow + 12)
  }
  const posts = allWpPost.nodes.map(e => e)

  return (
    <>
      <SEO 
       title={category?.seo?.title}
       desc={category?.seo?.metaDesc}
       ogtitle={category?.seo?.opengraphTitle}
       ogurl={`/blog/category` + category?.seo?.opengraphUrl}
       ogdesc={category?.seo?.opengraphDescription}
       ogbanner={category?.seo?.opengraphImage?.mediaItemUrl}
       tweettitle={category?.seo?.twitterTitle}
       tweetdesc={category?.seo?.twitterDescription}
       tweetbanner={category?.seo?.twitterImage?.mediaItemUrl}
       metaRobotsNoindex={data?.wpPage?.seo?.metaRobotsNoindex}
       canonical={`https://webmobtech.com${location.pathname}`}
       />
      <Layout>
        <CategoryFilters data={blogData.nodes } />
        <div className="relative bg-transperant lg:pt-16 pt-16 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
          {/* <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div> */}
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
              <H2
                // className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
                title={`Category: ${category.name}`}
              />

              <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-700 sm:mt-4">
                {category.description}
              </p>
            </div>
            <div className="mt-12 grid gap-16  max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
              {chunk(posts.slice(0, postsToShow), 3).map(e =>
                e.map(f => <BlogCard {...f} />)
              )}
            </div>
            {postsToShow <= totalCount && (
              <div className={`mt-24  flex justify-center `}>
                <button
                  aria-hidden="true"
                  onClick={handleLoadMore}
                  className="bg-blue-500 inline-flex relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
                >
                  See more
                </button>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default CategoryTemplate

export const query = graphql`
  query($databaseId: Int!) {
    allWpPost: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $databaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        date(formatString: "MMM DD, YYYY")
        uri
        excerpt
        featuredImage {
          node {
            mediaItemUrl
          }
        }
        author {
          node {
            avatar {
              url
            }
            name
            uri
          }
        }
        categories {
          nodes {
            name
            link
          }
        }
      }
    }
    category: wpCategory(databaseId: { eq: $databaseId }) {
      seo {
        title
        metaDesc
        opengraphUrl
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterDescription
        twitterTitle
        twitterImage {
          mediaItemUrl
        }
        metaRobotsNoindex
      }
      name
      description
    }
    blogData:allWpPost(sort: { fields: date, order: DESC }) {
      totalCount
      nodes {
        title
        date(formatString: "MMM DD, YYYY")
        uri
        excerpt
        featuredImage {
          node {
            mediaItemUrl
          }
        }
        author {
          node {
            avatar {
              url
            }
            name
            uri
          }
        }
        categories {
          nodes {
            name
            link
          }
        }
      }
    }
  }
`
