import { Link } from "gatsby"
import React, { useState } from "react"
// import {Location} from '@reach/router'
import { useLocation } from "@reach/router"

export default function CategoryFilters(props) {
  const location = useLocation()

  const data = props.data
  const mapdata = data.map(val => val.categories.nodes[0])
  const [dropDown, setDropDown] = useState(false);

  function getUniqueListBy(mapdata, key) {
    return [...new Map(mapdata.map(item => [item[key], item])).values()]
  }
  const filterData = getUniqueListBy(mapdata, "name")

  const handleDropDown = () => {
    setDropDown(!dropDown)
  }

  const patharr = location.pathname.split('/')
  const joinpath = '/' + patharr.slice(2, patharr.length).join('/')

  const itemname = filterData.find(val => val.link === joinpath)?.name
  return (
    <>
{
  dropDown&&(
    <div className="fixed top-0 h-screen bg-black bg-opacity-50 w-full z-10" aria-hidden="true" onClick={handleDropDown}>

    </div>
  )
}
      <div className="hidden lg:block relative">
        <div
          className={`lg:border-b-2 lg:border-gray-200 lg:bg-white w-full z-10 relative mt-16`}  //do mt-28 gitex or ces event else mt-16
        >
          <div className=" lg:flex flex-wrap lg:justify-center w-full px-3 md:px-8 lg:space-x-2 space-y-2 md:space-y-3 lg:space-y-0 ">
          <Link to="/blog/"> 
            <p
              className={`whitespace-nowrap px-2 md:px-3 lg:px-4 mr-2 md:mr-4 lg:mr-0 inline-block  lg:py-4 border-b-2 border-transparent hover:border-b-2 hover:border-blue-500 font-medium transform transition-all duration-300 ${location.pathname === `/blog/`
                ? " bg-blue-500 text-white lg:bg-transparent lg:text-blue-500 border-b-2 border-blue-500 "
                : "bg-white text-shark-400 focus:text-shark-500 hover:text-shark-500 "
                }`}
            >
              All
            </p>
            </Link>
            {filterData.map((item, index) => {
              return (
                <Link to={`/blog${item.link}`}>
                <p
                  key={index}
                  className={`whitespace-nowrap px-2 md:px-3 lg:px-4 mr-2 md:mr-3   lg:mr-0 inline-block lg:py-4 border-b-2 border-transparent hover:border-b-2 hover:border-blue-500 font-medium  transform transition-all duration-300  ${location.pathname === `/blog${item.link}`
                    ? " bg-blue-500 text-white lg:bg-transparent lg:text-blue-500 border-b-2 border-blue-500 "
                    : "bg-white text-shark-400 focus:text-shark-500 hover:text-shark-500 lg:bg-transparent "
                    }`}
                >
                  {item.name}
                </p>
                </Link>
              )
            })}
          </div>
        </div>
      </div>

      {/* For mobail */}
      <div className="relative w-full z-10 lg:hidden">
        <div
          className={`mt-16`} //do mt-28 gitex or ces event else mt-16
          
        >
            <div className="flex items-center bg-white  top-0 w-full z-20 lg:hidden relative shadow md:pt-2">
              <div className="flex px-4 py-4 w-full justify-between space-x-2">
                <div className="text-shark-400">
                  <p>Filter Category</p>
                </div>
                <div className=" flex items-center space-x-1" onClick={handleDropDown} aria-hidden="true">
                  <p className="text-shark-400">
                    {itemname ? itemname : "All"}
                  </p>
                  {
                    dropDown ? <svg className="h-4 w-4 transform rotate-180 text-shark-400" stroke="currentColor" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg> :
                      <svg className="h-4 w-4 transform text-shark-400" stroke="currentColor" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
                  }
                </div>
              </div>
            </div>
            {
              dropDown ?
                  <div className="absolute  rounded-md rounded-t-none z-0 px-4 w-full right-0 flex flex-wrap  items-center bg-white shadow-xl pb-6 pt-6" onClick={handleDropDown} aria-hidden="true">
                    <p className={`text-center px-3 py-1 mb-2  rounded mr-2 text-xs ${location.pathname === `/blog/` ? "border text-blue-500 border-blue-500" : "border border-shark-400 text-shark-400"}`}>
                      <Link to="/blog/" className=""> All</Link>
                    </p>
                    {filterData.map((item, index) => {
                      return (
                        <p
                          key={index}
                          className={`text-center px-3 py-1 mb-2 rounded mr-2 text-xs ${location.pathname === `/blog${item.link}` ? "border text-blue-500 border-blue-500" : "border border-shark-400 text-shark-400"}`}
                        >
                          <Link to={`/blog${item.link}`} className="">{item.name} </Link>
                        </p>
                      )
                    })}
                  </div>
                : ""
            }
        </div>
      </div>
    </>
  )
}
